import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import {Button} from "react-bootstrap";
import qaLogo from "../assets/img/page-images/gc-qa-color-logo.png";
import ibmftLogo from "../assets/img/page-images/IBMFTConnector-logo-sized.png";
import obsLogo from "../assets/img/page-images/gc-observations-color-logo.png";
import QaScreenShot from "../assets/img/splash/qaScreenshotOnTablet.png";
import ObsDashboardHeader from "../assets/img/splash/obs-dashboard-header.png";
import ObsDashboardImg from "../assets/img/splash/obs-dashboard-image.png";
import IbmftScreenShot from "../assets/img/page-images/cc-laptop.png";
import IbmFtDashboard from "../assets/img/splash/ibmft-dashboard.png";
import truckLicenseCap from "../assets/img/splash/truck-license-cap.png";
import truckInspectionChecklist from '../assets/img/splash/truck-inspection-checklist.png';
import chalkboard from "../assets/img/splash/ChalkboardWFrame-flat.png";
import hubHexagons from "../assets/img/page-images/hub-hexagons-new.png";



export default class GlasschainProductsPage extends Component {

  constructor(props)
  {
    super(props);
    this.downloadProductSheet = this.downloadProductSheet.bind(this)
  }



  downloadProductSheet(ev, productSheetName)
  {
    ev.preventDefault();
    window.open(
      "https://gcblobstoredurable1.blob.core.windows.net/userguidesdurable/" + productSheetName + ".pdf",
      "_blank"
    );
  }



    render() {

        return (

        <div className="productscontainer">


          <div className="hashnavdummy" id="qa"/>
          <div className="productsection mobileflex">
            <img style={{width: "30vmin", alignContent: "center", marginLeft: "25vw", marginBottom: "10px"}} src={qaLogo} alt="QA logo" />
            <div className="centerheader">Glasschain Observations QA</div>
            <p>Powerful QA for your testing team</p>

            <div className="sectionbody">
              <div>
                <img style={{width: "30vmin"}} src={QaScreenShot} alt="QA screen" />
                <img style={{width: "30vmin"}} src={ObsDashboardHeader} alt="Dashboard header" />
              </div>


              <div className="sectiontext">
                <p>Developed specifically to solve a hard problem in produce: how to effectively measure, monitor and improve product quality. If you want to step up your QA game and reduce costs at the same time, Glasschain will help make it happen.
                  Pre-defined inspection schemas for most commodities get you up and running quickly.
                  <br/><br/>
                  A general tool easily configurable for specific uses including QA inspections, farming operations monitoring, in market assessments, 
                  and compliance confirmations.
                  <br/><br/>
                  If you have information blind spots in your organization, Observations can capture event data and restore visibility.  Runs on any 
                  mobile device and even offline.  Aggregate and integrate with operational data for real time monitoring and decision making.  
                  <br/><br/>
                  Pre-defined inspection templates for many commodities get you up and running quickly. Standard features include defect "slide shows" that 
                  can be associated with specific defects or across the inspection, image capture directly from your device's camera or uploaded from your
                  camera roll, voice-to-text notes with language translation, email and/or text notications triggered on specific results or every inspection.
                  <br/><br/> 
                  An Admin Dashboard is included for real time visibility and metrics as well as user and notification subscription administration. 
                  </p>
                <Button  onClick={(e)=>this.downloadProductSheet(e, "Product-Sheet-Observations")}>download product sheet</Button>
              </div>

            </div>
        </div>


          <div className="hashnavdummy" id="ibmft"/>
          <div className="productsection mobileflex">
          <img style={{width: "30vmin", alignContent: "center", marginLeft: "25vw", marginBottom: "10px"}} src={ibmftLogo} alt="IBM Food Trust Logo" />
            <div className="centerheader">IBM Food Trust integration</div>
            <p>Set-and-Forget Compliance Integration</p>

            <div className="sectionbody">
            <div>
              <img style={{width: "30vmin", marginBottom: "20px"}} src={IbmftScreenShot} alt="IBM Food Trust Integration" />
              <img style={{width: "30vmin", marginBottom: "20px"}} src={IbmFtDashboard} alt="IBM Food Trust Dashboard" />
            </div>
              <div className="sectiontext">
                <p>Nothing stings more than having your value-add projects interrupted by a "need-to-have" compliance request from a retail partner.
                  Glasschain's IBM Food Trust Integration is a cloud-based plug-and-play solution built to take the burden of complex retrieve, transform
                  and transmit details and ongoing monitoring and maintenance off your shoulders. Readiness compliance can go from months to days - saving you
                  time and money, and letting your team get back to work. 
                  <br/><br/>
                  A compliance-as-a-Service (CaaS) platform serving as the translator from your produce transactional systems to the IBM Food Trust blockchain.  
                  <br/><br/>
                  If you are facing a retail customer mandate to join the blockchain, this service is your fastest and least expensive compliance option.
                  <br/><br/>
                  You have complete control over what data is posted to the blockchain and when.  Food Trust Connector also provides helpful shipment event alerting and fast and simple lot trace visibility.
                </p>
                <Button onClick={(e)=>this.downloadProductSheet(e, "Product-Sheet-IBMFT")}>download product sheet</Button>
              </div>
            </div>
        </div>





        <div className="hashnavdummy" id="observations"/>
        <div className="productsection mobileflex">
        <img style={{width: "30vmin", alignContent: "center", marginLeft: "25vw", marginBottom: "10px"}} src={obsLogo} alt="Observations Logo" />
           <div className="centerheader">Glasschain Observations</div>
            <p>Empower your team to collect <i>your</i> data</p>

            <div className="sectionbody">
              <div>
                <img style={{width: "30vmin", marginBottom: "20px"}} src={truckLicenseCap} alt="license capture in observations" />
                <img style={{width: "30vmin", marginBottom: "20px"}} src={truckInspectionChecklist} alt="truck inspection checklist" />
              </div>

              <div className="sectiontext">
                <p>The platform on which our Glasschain Qa system is based, Glasschain Observations let's you collect any kind of data you can think of.
                  Using pre-defined or custom templates you can capture anything from truck inspection checklists to... All in a simple
                  to use, mobile-ready online or offline format, including image and voice note and signature capture. 
                </p>
                <Button   onClick={(e)=>this.downloadProductSheet(e, "Product-Sheet-Observations")}>download product sheet</Button>
              </div>
            </div>
        </div>


        <div className="hashnavdummy" id="customdev"/>
        <div className="productsection mobileflex">
            <div className="centerheader">Glassbit Custom Development</div>
            <p>We're here to help</p>

            <div className="sectionbody">
              <div>
                <img  style={{height: "25vmin", marginBottom: "20px"}} src={chalkboard} alt="glassbit chalkboard" />
              </div>


              <div className="sectiontext">
                <p>
                The Glassbit principals have a combined decades of experience improving 
                grower/shipper processes and data access via custom application solutions.  We 
                understand the nuances of the produce business including farming, operations, 
grower accounting, and actionable metrics creation. And especially how to handle 
the often conflicting requirements of produce complexity and scale.
Every Glasschain app subscription includes The Hub which is foundational to our 
custom application solutions.  The Hub provides the produce-specific middleware 
and database services to speed development and leverage built-in cloud 
capabilities.  It also includes an integration tool set connecting to ERP like Famous, 
Microsoft Dynamics, NetSuite and even highly modified and home-grown systems.
</p>
<div>
  <img  style={{height: "30vmin", marginBottom: "20px"}} src={hubHexagons} alt="glasschain hub" />
</div>
<p>
Successful custom application solutions to date include:
<ul>
  <li>Nursery plant tracking including issuance and billing</li>
  <li>Mobilized receiving front-end to Famous and Dynamics</li>
  <li>Quality assurance assessment integrated to receiving and grower accounting</li>
  <li>Field data collection and analysis</li>
  <li>Grower accounting including pooling and indexing integrated to GL and AP</li>
  <li>IBM Food Trust integration with set-and-forget compliance</li>
  <li>Supplier settlement reporting including complex Famous repack/restyle rules and analysis</li>
  <li>Data entry robot working around non-existent APIs (Application Program Interfaces)</li>
</ul>
</p>
<p>
If you have operational or reporting needs not satisfied by your existing ERP, reach 
out for an exploratory call to see if we are potential match for your requirements.  
You can even extend the life of your existing ERP with integrated Glassbit solutions.
</p>
            <HashLink to="/main/contact-page#top"><Button>learn more</Button></HashLink>
              </div>
            </div>
        </div>


        </div>

        );
    }



}