import RequestService from './RequestService.js';
import AppConfig from '../config/AppConfig.js';




export default class GlasschainHttpClient {
    // Initializing important variables
    constructor() {
        this.appConfig = new AppConfig();
        this.domain = this.appConfig.getCoreServiceHost(); //'https://gc-core-svc.azurewebsites.net' // API server domain
        this.requestService = new RequestService(this.domain);
    }

    async postContactForm(contactForm){
        try {
        const url = '/api/info/marketing/formdatarequest';
        await this.requestService.postJson(url, contactForm, false);
        }
        catch(err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


}
