import {appsettings} from '../../variables/AppSettings.js';   //'../../variables/appSettings.js';

const coreServiceHostKey = "glasschain.core.host.addr";


export default class AppConfig{
    constructor(){
        this.appsettings = appsettings;
    }

    getCoreServiceHost(){
        return this.getValue(coreServiceHostKey);
    }




    hasKey(key){
        return this.appsettings.some(o => o.key === key);
    }

    getValue(key){
        let obj = this.appsettings.find(o => o.key === key);
        return obj.value;
    }



    

}