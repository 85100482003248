import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import './web.config';


import GlassbitHomeLayout from "../src/layouts/GlassbitHomeLayout.jsx";
import GlasschainProductsPage from './pages/GlasschainProductsPage';




ReactDOM.render(
    <HashRouter>
      <Switch>
        <Route path="/main" component={GlassbitHomeLayout} /> 
        <Route path="/main/products-page" component={GlasschainProductsPage} /> 
        <Redirect from="/" to="/main" />
      </Switch>
    </HashRouter>,
    document.getElementById("root")
  );

