import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/header.css";
import BurgerIcon from "../../assets/img/splash/burger-icon-white.png";


export default class GlassbitHeader extends Component {


    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        };

        this.onBurgerToggle = this.onBurgerToggle.bind(this);
      }


    onBurgerToggle(ev)
    {
        this.setState({showMenu: !this.state.showMenu});
    }  


    render() {
        let showing = "visible";
        let isMobile = (this.props.windowWidth < 700);
        if (isMobile && !this.state.showMenu){
            showing = "hidden";
        }
 
        /*
        if(this.props.location.pathname === "/main/home-page"){
             showing = "visible"
        } else if(this.props.windowWidth >= 700) {
             showing = "visible"
        } else {
             showing = "hidden"
        }
        if(this.props.windowWidth < 600 && this.props.location.pathname !== "/main/home-page"){
            showing="hidden"
        }
        let headerClassNames = ''
        if(this.props.windowWidth > 600){
            headerClassNames = (this.props.fade) ? "Header gb-nav" : "Header";
        }else{
            headerClassNames = "Header"
        }
        */

        return (
            <header className="Header gb-nav">
                <a href="/"><img src={require("../../assets/img/logos/glassbit-logo-rect-transp.png")} className="Logo" alt="glassbit logo" /></a>
                <nav className="Nav gb-nav-item" style={{visibility: showing }}>
                    <Link to="/main/products-page" onClick={this.onBurgerToggle}>products</Link>
                    <Link to="/main/partners-page" onClick={this.onBurgerToggle}>partners</Link>
                    <Link to="/main/about-page" onClick={this.onBurgerToggle}>about</Link>
                    <Link to="/main/contact-page" style={{ color: "#e87414" }}  onClick={this.onBurgerToggle}>get in touch</Link>
                </nav>
                <div className="Burger" onClick={(e) => this.onBurgerToggle(e)}><img id="burgerIcon" src={BurgerIcon} alt="menu"/></div>
            </header>
        )
    } 
}