import React, { Component } from "react";
import {Container, Row, Col} from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import "../assets/css/stdpage.css";
import "../assets/css/cardflip.css";

import frontImg from "../assets/img/test-images/monk-portrait-cropped.png"
import backImg from "../assets/img/test-images/SeenFromBehindCover-cropped.png"






export default class GlassbitCardFlipPage extends Component {

    constructor(props){
        super(props);
    }



  render() {
    return (
        <div className = "page-container">
        <div className="card-wrapper flip-right">
            <div className = "card">
                <div className="front">FRONT</div>
                <div className="back">BACK</div>
            </div>
        </div>

        <div className="card-wrapper flip-right">
            <div className = "card">
                <div className="front">FRONT 2</div>
                <div className="back">BACK 2</div>
            </div>
        </div>

        <div className="card-wrapper flip-right">
            <div className = "card">
                <div className="front"><img src={frontImg}/></div>
                <div className="back"><img src={backImg}/></div>
            </div>
        </div>

        </div>
    );
  }
}