import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Flip from "../components/gadgets/PartnerFlipCard.jsx";

import "../assets/css/stdpage.css";


import BrightFarmsLogo from "../assets/img/partners/BrightFarms-logo-box.png"
import CalGiantLogo from "../assets/img/partners/CalGiant-logo-box.png"
import OceanMistLogo from "../assets/img/partners/OceanMist-logo-box.png"
import LMLogo from "../assets/img/partners/LM-logo-box.png"
import IBMLogo from "../assets/img/partners/IBM-logo-box.png"
import IBMFoodTrustLogo from "../assets/img/partners/IBMFoodTrust-logo-box.png"
import WalmartLogo from "../assets/img/partners/Walmart-logo-box.png"
import SamsClubLogo from "../assets/img/partners/SamsClub-logo-box.png"
import StopAndShopLogo from "../assets/img/partners/StopAndShop-logo-box.png"
import WakefernLogo from "../assets/img/partners/Wakefern-logo-box.png"
import HaywardScoreLogo from "../assets/img/partners/HaywardScore-logo-box.png"
import SuperiorFoodsLogo from "../assets/img/partners/SuperiorFoods-logo-box.png"
import MicrosoftLogo from "../assets/img/partners/Microsoft-logo-box.png"
import MicrosoftAzureLogo from "../assets/img/partners/MicrosoftAzure-logo-box.png"

import CalGiantwhite from "../assets/img/partners/CalGiant-logo-sized.png"
import OceanMistwhite from "../assets/img/partners/OceanMist-logo-sized.png"
import LandMwhite from "../assets/img/partners/LM-logo-sized.png"
import BrightFarmswhite from "../assets/img/partners/BrightFarms-logo-sized.png"

export default class GlassbitPartnersPage extends Component {


  render() {

    let pageVersion;
    if(this.props.windowWidth > 600){
      pageVersion = <div className="page-container">
        <Row style={{ marginLeft: "5vw", marginright: "5vw" }}>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={OceanMistLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={CalGiantLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={BrightFarmsLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={LMLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={WalmartLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={SamsClubLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={StopAndShopLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={WakefernLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={IBMLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={IBMFoodTrustLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={MicrosoftLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={MicrosoftAzureLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={HaywardScoreLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12}>
            <Flip
              front={SuperiorFoodsLogo}
              frontHeight="180px"
              frontWidth="298px"
            />
          </Col>
        </Row>
      </div>
    } else {
      pageVersion = <div style={{backgroundColor: "white !important", paddingTop: "75px"}}>
        <img src={CalGiantLogo} alt="CG" style={{maxWidth: "100vw"}}/>
        <img src={OceanMistLogo} alt="OM" style={{maxWidth: "100vw"}}/>
        <img src={LMLogo} alt="LM" style={{maxWidth: "100vw"}}/>
        <img src={BrightFarmsLogo} alt="BF" style={{maxWidth: "100vw"}}/>
        <img src={IBMLogo} alt="IBM" style={{maxWidth: "100vw"}}/>
        <img src={WalmartLogo} alt="WM" style={{maxWidth: "100vw"}}/>
        <img src={SamsClubLogo} alt="SC" style={{maxWidth: "100vw"}}/>
        <img src={StopAndShopLogo} alt="SS" style={{maxWidth: "100vw"}}/>
        <img src={WakefernLogo} alt="WF" style={{maxWidth: "100vw"}}/>
        <img src={HaywardScoreLogo} alt="HS" style={{maxWidth: "100vw"}}/>
        <img src={SuperiorFoodsLogo} alt="SF" style={{maxWidth: "100vw"}}/>
        <img src={MicrosoftLogo} alt="MS" style={{maxWidth: "100vw"}}/>
      </div>
    }


    return (
      // <div className="page-container">

      //   <Row style={{ marginLeft: "5vw", marginright: "5vw" }}>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={OceanMistLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={CalGiantLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={BrightFarmsLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={LMLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>

      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={WalmartLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={SamsClubLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={StopAndShopLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={WakefernLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>

      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={IBMLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={IBMFoodTrustLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={MicrosoftLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={MicrosoftAzureLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>

      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={HaywardScoreLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>
      //     <Col xl={3} lg={4} sm={6} xs={12}>
      //       <Flip
      //         front={SuperiorFoodsLogo}
      //         frontHeight="180px"
      //         frontWidth="298px"
      //       />
      //     </Col>

      //   </Row>



      // </div>
      <div>{pageVersion}</div>
    );
  }



}