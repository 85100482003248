import React, { Component } from "react";
import {HashLink } from 'react-router-hash-link';
import {Button} from "react-bootstrap";
import Marquee from "react-fast-marquee";

import "../assets/css/homepage.css";
import bgimage from "../assets/img/splash/glassbit-jellyfade-black.png";
import fadeimage from "../assets/img/splash/glassbit-jellyfade-color.png";

import BrightFarmsLogo from "../assets/img/partners/BrightFarms-logo-box.png"
import CalGiantLogo from "../assets/img/partners/CalGiant-logo-box.png"
import OceanMistLogo from "../assets/img/partners/OceanMist-logo-box.png"
import LMLogo from "../assets/img/partners/LM-logo-box.png";
import IBMLogo from "../assets/img/partners/IBM-logo-box.png";
import IBMFoodTrustLogo from "../assets/img/partners/IBMFoodTrust-logo-box.png"
import WalmartLogo from "../assets/img/partners/Walmart-logo-box.png"
import SamsClubLogo from "../assets/img/partners/SamsClub-logo-box.png"
import StopAndShopLogo from "../assets/img/partners/StopAndShop-logo-box.png"
import WakefernLogo from "../assets/img/partners/Wakefern-logo-box.png"
import HaywardScoreLogo from "../assets/img/partners/HaywardScore-logo-box.png"
import SuperiorFoodsLogo from "../assets/img/partners/SuperiorFoods-logo-box.png"
import MicrosoftLogo from "../assets/img/partners/Microsoft-logo-box.png"
import MicrosoftAzureLogo from "../assets/img/partners/MicrosoftAzure-logo-box.png";
import PaloAltoNetWorksLogo from "../assets/img/partners/PaloAltoNetworks-logo-box.png";

import QaScreenShot from "../assets/img/splash/qaScreenshotOnTablet.png";
import IbmftScreenShot from "../assets/img/page-images/cc-laptop.png";
import truckLicenseCap from "../assets/img/splash/truck-license-cap.png";
import truckInspectionChecklist from '../assets/img/splash/truck-inspection-checklist.png';


export default class GlassbitHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  
  

  render() {

    return (
      <div className="homecontainer">
        <div className="mainsplash">
          <img src={bgimage} className="base-image" alt="base"/>
          <img src={fadeimage} className="fade-image" alt="fade"/>
        </div>


        <div className="leadbox mobileflex">
          <div class="leftheader">game changing <br/> cloud solutions <br/>for grower shippers</div>
          <p>practical mobile and web software <br/>for gathering and sharing information <br/>that meets our industry's unique requirements </p>
        </div>


        <div className="partnerbox mobileflex">
          <p>Join the companies partnering with the glasschain platform</p>
          <div className="partnerimages">
            <Marquee
              direction="right"
              pauseOnHover="true"
              gradientColor={[0, 0, 0]}
            >
                <img className="marqueeimage" src={IBMLogo} alt="ibm"/>
                <img className="marqueeimage" src={HaywardScoreLogo} alt="Hayward Score"/>
                <img className="marqueeimage" src={CalGiantLogo} alt="Cal Giant" />
                <img className="marqueeimage" src={BrightFarmsLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={LMLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={MicrosoftAzureLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={OceanMistLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={PaloAltoNetWorksLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={SamsClubLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={WakefernLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={SuperiorFoodsLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={MicrosoftLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={IBMFoodTrustLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={WalmartLogo} alt="Bright Farms" />
                <img className="marqueeimage" src={StopAndShopLogo} alt="Stop n Shop" />


            </Marquee>
          </div>
        </div>


        <div className="productsection mobileflex">
            <div className="centerheader">Powerful QA for your testing team</div>
            <p>Glasschain Observations QA - for the way <i>you</i> test</p>

            <div className="sectionbody">
            <img src={QaScreenShot} alt="qa" />

              <div className="sectiontext">
                <p>Developed specifically to solve a hard problem in produce: how to effectively measure, monitor and improve product quality. If you want to step up your QA game and reduce costs at the same time, Glasschain will help make it happen.
                  Pre-defined inspection schemas for most commodities get you up and running quickly.</p>
                
                  <HashLink to="/main/products-page#qa"><Button>learn more</Button></HashLink>
              </div>
            </div>
        </div>


        <div className="productsection mobileflex">
            <div className="centerheader">Set-and-Forget Compliance Integration</div>
            <p>IBM Food Trust integration</p>

            <div className="sectionbody">
              <div className="sectiontext">
                <p>Nothing stings more than having your value-add projects interrupted by a "need-to-have" compliance request from a retail partner.
                  Glasschain's IBM Food Trust Integration is a cloud-based plug-and-play solution built to take the burden of complex retrieve, transform
                  and transmit details and ongoing monitoring and maintenance off your shoulders. Readiness compliance can go from months to days - saving you
                  time and money, and letting your team get back to work. 
                </p>
                <HashLink to="/main/products-page#ibmft"><Button>learn more</Button></HashLink>
              </div>
              <img src={IbmftScreenShot} style={{height: "45%", width: "45%"}}alt="ibmft" />
            </div>
        </div>






        <div className="productsection mobileflex">
            <div className="centerheader">Empower Your Team to Collect <i>Any</i> Data</div>
            <p>Glasschain Observations</p>

            <div className="sectionbody">
            <img src={truckInspectionChecklist} alt="truck inspection checklist" />

              <div className="sectiontext">
                <p>The platform on which our Glasschain Qa system is based, Glasschain Observations let's you collect any kind of data you can think of.
                  Using pre-defined or custom templates you can capture anything from truck inspection checklists to... All in a simple
                  to use, mobile-ready online or offline format, including image and voice note and signature capture. 
                </p>
                <HashLink to="/main/products-page#observations"><Button>learn more</Button></HashLink>
              </div>
            </div>
        </div>






        <div className="productsection mobileflex">
            <div className="centerheader">Ready to see it in action?</div>
            <p>Let's go!</p>
            <div className="sectionbody">
              <div className="sectiontext">
                <p>Get in touch with us to discuss how we might help you and to see a demo of any of our products. We look forward to hearing from you. 
                </p>
                <HashLink to="/main/contact-page#top"><Button>get a demo</Button></HashLink>
              </div>
            </div>
        </div>





      </div>




    );
  }
}
