import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../assets/css/cardgrow.css";

export default class NewsFlipCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {


    return (

      <div className="grow-card">
        <div>
          {this.props.headline}
        </div>
        <div>
          {this.props.articleDate}
        </div>
        <div className="introContent">
          {this.props.intro}
        </div>
        <div className="fullContent">
          <img src={this.props.imgUrl} alt={this.props.imgUrl}/>
          {this.props.textHtml}
        </div>

      </div>




    );
  }
}
