import React, { Component } from "react";
import "../../assets/css/cardflipw3.css";

export default class FlipCard extends Component {


  render() {

    const cardBackfaceVisibility = (this.props.back) ? "hidden" : "visible";

    return (
      /*<div className="card-wrapper flip-right">
        <div className="card">
          <div className="front">
            <img src={this.props.front} />
          </div>
          <div className="back">
          <img src={this.props.back} />
          </div>
        </div>
      </div>*/


      <div className="flip-card" style={{width:this.props.frontWidth, height:this.props.frontHeight}}>
        <div className="flip-card-inner">
          <div className="flip-card-front" style={{backfaceVisibility:cardBackfaceVisibility, WebkitBackfaceVisibility: cardBackfaceVisibility}}>
          <img style={{maxWidth:this.props.frontWidth}} src={this.props.front} />
          </div>
          <div className="flip-card-back" style={{backfaceVisibility:cardBackfaceVisibility, WebkitBackfaceVisibility: cardBackfaceVisibility}}>
            <img src={this.props.back} />
          </div>

        </div>
      </div>



    );
  }
}
