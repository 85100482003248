import React, { Component } from "react";
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import Header from "../components/navigation/GlassbitHeader.js";
import GlassbitHomePage from "../pages/GlassbitHomePage.jsx";
import GlasschainProductsPage from "../pages/GlasschainProductsPage.jsx";

import GlassbitAboutPage from "../pages/GlassbitAboutPage.jsx";
import GlassbitCustomerStoriesPage from "../pages/GlassbitCustomerStoriesPage.jsx";
import GlassbitPartnersPage from "../pages/GlassbitPartnersPage.jsx";
import GlassbitNewsPage from "../pages/GlassbitNewsPage.jsx";
import GlassbitContactUsPage from "../pages/GlassbitContactUsPage.jsx";


import GlassbitCardFlipPage from "../pages/GlassbitCardFlipPage.jsx";
import JellyboneRandomizerPage from "../pages/jellybone-randomizer-page.jsx";


export default class GlassbitHomeLayout extends Component {
    constructor(props){
        super(props);
        this.state = {
            headerFade: true,
            windowWidth: window.innerWidth,
            showHeader: true
        }

        this.shouldShowNav = this.shouldShowNav.bind(this)
    }

    componentDidMount = () => {
        console.log("animation start...");
        this.performAndDisappear();
    }

    performAndDisappear = () => {
        const element = document.getElementById('animation-root');
        element.addEventListener('animationend', ()=> {
            console.log("Animation complete!");
            this.setState({headerFade: true});
        })
    }

    shouldShowNav(shouldShow){
        if(shouldShow === true){
            this.setState({
                showHeader: true
            })
        } else {
            this.setState({
                showHeader: false
            })
        }
    }


    render() {
        return (
            <div className="wrapper" id="animation-root">
                <div 
                className="main-panel" 
                ref="mainPanel"
                style={{position: "absolute", zIndex: "-2", backgroundColor: "black", width: "100vw" }}                
                >
                    <Header
                        {...this.props}
                        fade={this.state.headerFade}
                        show={this.state.showHeader}
                        windowWidth = {this.state.windowWidth}
                        />
                    <Switch>
                    <Route
                        path="/main/home-page"
                        render={(props) => (<GlassbitHomePage {...props} windowWidth = {this.state.windowWidth} />)}
                    />
                    <Route
                        path="/main/products-page"
                        render={(props) => (<GlasschainProductsPage {...props} windowWidth = {this.state.windowWidth}  />)}
                    />
                    <Route
                        path="/main/partners-page"
                        render={(props) => (<GlassbitPartnersPage {...props} windowWidth = {this.state.windowWidth}/>)}
                    />
                    <Route
                        path="/main/customerstories-page"
                        render={(props) => (<GlassbitCustomerStoriesPage {...props} windowWidth = {this.state.windowWidth}/>)}
                    />
                    <Route
                        path="/main/cardflip-page"
                        render={(props) => (<GlassbitCardFlipPage {...props} windowWidth = {this.state.windowWidth}/>)}
                    />
                    <Route
                        path="/main/news-page"
                        render={(props) => (<GlassbitNewsPage {...props} />)}
                    />
                    <Route
                        path="/main/about-page"
                        render={(props) => (<GlassbitAboutPage {...props} windowWidth = {this.state.windowWidth}/>)}
                    />
                    <Route
                        path="/main/contact-page"
                        render={(props) => (<GlassbitContactUsPage {...props} />)}
                    />
                    <Route
                        path="/main/jellybone-randomizer-page"
                        render={(props) => (<JellyboneRandomizerPage {...props} />)}
                    />
                        <Redirect from="/" to="/main/home-page" />
                        <Redirect from="/main" to="/main/home-page" />
                    </Switch>
                </div>
            </div>

        );
    }



}