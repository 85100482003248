import React, { Component } from "react";
import {Form,Container,Row,Col,Button} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import "../../src/assets/css/stdpage.css";
import GlasschainHttpClient from "../components/apiclients/GlasschainHttpClient.js";

export default class GlassbitContactUsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      loading: false,
    };

    //this.apiClient = new GlasschainHttpClient();
    this.apiClient = new GlasschainHttpClient();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Complete"
          onConfirm={() => {
            this.setState({
              alert: null,
            });
            //this.props.history.push('/alertlist');
          }}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
        >
          Thanks for reaching out! We will quickly respond to start the conversation and explore 
          if  Glasschain products and integration tools can add value to your organization. As
          always, we'll keep your information private.
        </SweetAlert>
      ),
    });
  }

  stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
      data[key] = fd.get(key);
    }
    return JSON.stringify(data, null, 2);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const data = new FormData(ev.target);
    var stringData = this.stringifyFormData(data);
    //alert(stringData);
    // construct json out of it. Should be an automated way of doing this?
    //var postData = {"name": data.name, "title": data.title, "company": data.company, "email": data.email,
    //    "motivation": data.motivation, "transactionsystem" : data.transactionsystem, "comments": data.comments };
    try {
      await this.apiClient.postContactForm(stringData);
      this.successAlert();
    } catch (err) {
      // we should see the error in the console. We'll need to log it at some point or we won't know
      // that there even was an error. But we don't want the user to see it.
      this.successAlert();
    }
  }

  validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.company) {
      errors.company = "Required";
    }
    return errors;
  };

  render() {
    return (
      <Container className=" container-fluid page-container" style={{paddingBottom: "90px"}}>
        <Row>
          <Col md={8} mdoffset={2}>
            <div className="header-text">
              <h2>Contact Us</h2>
            </div>
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit} validate={this.validate}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Control
                required
                name="name"
                placeholder="Name"
                type="text"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Control name="title" placeholder="Title" type="text" />
            </Form.Group>
          </Form.Row>
          <br />
          <Form.Control
            required
            name="company"
            placeholder="Company"
            type="text"
          />
          <br />
          <Form.Control
            required
            name="email"
            placeholder="Email Address"
            type="email"
          />
          <br />
          <Form.Row>
            <Form.Label>Motivation</Form.Label>
            <Form.Control required name="motivation" as="select">
              <option>choose one...</option>
              <option>Improve my product quality with a better assessment system</option>
              <option>Improve my operational visibility and decision making</option>
              <option>Integrate new operational capabilities with my legacy ERP system</option>
              <option>Comply with a customer mandate to join the IBM Food Trust blockchain</option>
              <option>License Glasschain SaaS tools for my own product or service</option>
              <option>Other (describe in comments)</option>
            </Form.Control>
          </Form.Row>
          <br />
          <Form.Row>
            <Form.Label>ERP/Transaction System</Form.Label>
            <Form.Control required name="transactionsystem" as="select">
              <option>choose one...</option>
              <option>ColumbusFood</option>
              <option>De Facto</option>
              <option>Dynamics 365</option>
              <option>Dynamics AX</option>
              <option>Dynamics NAV</option>
              <option>Famous</option>
              <option>iNECTA</option>
              <option>Infor</option>
              <option>JD Edwards</option>
              <option>LINKFRESH</option>
              <option>NetSuite</option>
              <option>Oracle</option>
              <option>Produce Magic</option>
              <option>Produce Pro</option>
              <option>Prophet</option>
              <option>SAP</option>
              <option>SI Food ERP</option>
              <option>Sysco</option>
              <option>QuickBooks</option>
              <option>Other (describe in comments)</option>
            </Form.Control>
          </Form.Row>
          <br />
          <Form.Row>
            <Form.Control
              placeholder="Comments"
              name="comments"
              as="textarea"
              row="3"
            />
          </Form.Row>
          <br />
          <Button type="submit">Submit</Button>
        </Form>
        <div>{this.state.alert}</div>
      </Container>
    );
  }
}
