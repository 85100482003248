import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import "../assets/css/stdpage.css";

import LookingGlassJelly from "../assets/img/page-images/gc-lookingglass-jelly-black.png";
import AboutFlipCard from "../components/gadgets/FlipCard";

import "../assets/css/stdpage.css";

import CalGiantCardFront from "../assets/img/test-images/CalGiant-Card-front.png";
import CalGiantCardBack from "../assets/img/test-images/CalGiant-Card-back.png"

import AboutPeteCardFront from "../assets/img/test-images/about-pete-front.png";
import AboutPeteCardBack from "../assets/img/test-images/about-pete-back.png"
import AboutCurtCardFront from "../assets/img/test-images/about-curt-front.png";
import AboutCurtCardBack from "../assets/img/test-images/about-curt-back.png"

import AboutLoriCardFront from "../assets/img/test-images/about-lori-front.png";
import AboutLoriCardBack from "../assets/img/test-images/about-lori-back.png"

import AboutDanCardFront from "../assets/img/test-images/about-dan-front.png";
import AboutDanCardBack from "../assets/img/test-images/about-dan-back.png"


export default class GlassbitAboutPage extends Component {

    constructor(props) {
        super(props);

    }


    render() {
        let pageVersion;
        if (this.props.windowWidth > 600) {
            pageVersion = <div className="page-container" style={{ marginTop: "15px" }}>
                <Row>
                    <hr style={{ borderColor: "grey" }} />
                </Row>
                <Row>
                    <Col style={{ textAlign: "center", marginLeft: "15px" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <AboutFlipCard
                                front={AboutPeteCardFront}
                                frontHeight="417px"
                                frontWidth="299px"
                                back={AboutPeteCardBack}
                            />
                            <AboutFlipCard
                                front={AboutCurtCardFront}
                                frontHeight="417px"
                                frontWidth="299px"
                                back={AboutCurtCardBack}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <AboutFlipCard
                                front={AboutLoriCardFront}
                                frontHeight="417px"
                                frontWidth="299px"
                                back={AboutLoriCardBack}
                            />
                            <AboutFlipCard
                                front={AboutDanCardFront}
                                frontHeight="417px"
                                frontWidth="299px"
                                back={AboutDanCardBack}
                           
                            />
                        </div>
                    </Col>
                    <Col>
                        <img src={LookingGlassJelly} />
                    </Col>
                </Row>
            </div>
        } else {
            pageVersion = <div style={{ paddingTop: "75px"}}>
                {/* <AboutFlipCard
                    front={AboutPeteCardFront}
                    frontHeight="417px"
                    frontWidth="299px"
                    back={AboutPeteCardBack}
                />
                <AboutFlipCard
                    front={AboutCurtCardFront}
                    frontHeight="417px"
                    frontWidth="299px"
                    back={AboutCurtCardBack}
                />
                <AboutFlipCard
                    front={AboutLoriCardFront}
                    frontHeight="417px"
                    frontWidth="299px"
                    back={AboutLoriCardBack}
                />
                <AboutFlipCard
                    front={AboutDanCardFront}
                    frontHeight="417px"
                    frontWidth="299px"
                    back={AboutDanCardBack}
                /> */}
                <img src={AboutPeteCardBack} style={{maxWidth: "90vw"}}></img>
                <img src={AboutCurtCardBack} style={{maxWidth: "90vw"}}></img>
                <img src={AboutLoriCardBack} style={{maxWidth: "90vw"}}></img>
                <img src={AboutDanCardBack} style={{maxWidth: "90vw"}}></img>
            </div>
        }
        return (
            // <div className="page-container" style={{ marginTop: "15px" }}>
            //     <Row>
            //         <hr style={{ borderColor: "grey" }} />
            //     </Row>
            //     <Row>
            //         <Col style={{ textAlign: "center", marginLeft: "15px" }}>


            //             <div style={{ display: "flex", flexDirection: "row" }}>
            //                 <AboutFlipCard
            //                     front={AboutPeteCardFront}
            //                     frontHeight="417px"
            //                     frontWidth="299px"
            //                     back={AboutPeteCardBack}
            //                 />

            //                 <AboutFlipCard
            //                     front={AboutCurtCardFront}
            //                     frontHeight="417px"
            //                     frontWidth="299px"
            //                     back={AboutCurtCardBack}
            //                 />
            //             </div>



            //             <div style={{ display: "flex", flexDirection: "row" }}>
            //                 <AboutFlipCard
            //                     front={AboutLoriCardFront}
            //                     frontHeight="417px"
            //                     frontWidth="299px"
            //                     back={AboutLoriCardBack}
            //                 />

            //                 <AboutFlipCard
            //                     front={AboutDanCardFront}
            //                     frontHeight="417px"
            //                     frontWidth="299px"
            //                     back={AboutDanCardBack}
            //                 />
            //             </div>




            //         </Col>
            //         <Col>
            //             <img src={LookingGlassJelly} />



            //         </Col>

            //     </Row>


            // </div>
            <span >{pageVersion}</span>
        );
    }



}