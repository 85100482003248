import React, { Component } from "react";
import {Row, Col} from "react-bootstrap";
import FlipCard from "../components/gadgets/FlipCard.jsx";
import "../assets/css/stdpage.css";

import CalGiantCardFront from "../assets/img/test-images/CalGiant-CustomerStory-front.png";
import CalGiantCardBack from "../assets/img/test-images/CalGiant-CustomerStory-back.png"
import OceanMistCardFront from "../assets/img/test-images/OceanMist-CustomerStory-front.png";
import OceanMistCardBack from "../assets/img/test-images/OceanMist-CustomerStory-back.png"

export default class GlassbitCustomerStoriesPage extends Component {



  render() {
    return (
        <div className="page-container">
         <Row>
                <Col style={{textAlign: "center", marginLeft: "15px"}}> 
                    <div style={{display: "flex", flexDirection: "row"}}>
                    <FlipCard 
                        front={CalGiantCardFront} 
                        frontHeight="417px"
                        frontWidth="299px"
                        back={CalGiantCardBack}

                    />
                <FlipCard 
                        front={OceanMistCardFront} 
                        frontHeight="417px"
                        frontWidth="299px"
                        back={OceanMistCardBack}

                    />
                </div>

                <div style={{display: "flex", flexDirection: "row"}}>
                    <FlipCard 
                        front={CalGiantCardFront} 
                        frontHeight="417px"
                        frontWidth="299px"
                        back={CalGiantCardBack}

                    />
                <FlipCard 
                        front={OceanMistCardFront} 
                        frontHeight="417px"
                        frontWidth="299px"
                        back={OceanMistCardBack}

                    />
                </div>

                </Col>

            </Row>
        </div>
    );
  }


  
}