import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import "../assets/css/stdpage.css";

import LookingGlassJelly from "../assets/img/page-images/gc-lookingglass-jelly-black.png";
import NewsFlipCard from "../components/gadgets/NewsFlipCard";

import Headline from "../assets/img/news/bluebook-glasschainheadline.png";
import Headline2 from "../assets/img/news/freshplaza-glasschainheadline.png";





export default class GlassbitNewsPage extends Component {

    constructor(props) {
        super(props);
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        this.state = {
            date: date
        };
    }


    componentDidMount() {

        let acc = document.getElementsByClassName("accordion");
        var i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                let elms = document.querySelectorAll('.accordion');
                elms.forEach(element => {
                    // element.classList.toggle("active");
                    let elmPanel = element.nextElementSibling;
                    elmPanel.style.maxHeight = null;
                });
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
   
            });
        }
    }

    render() {


        return (

            <div className="page-container">


                <div className="news-container">
                    <button class="accordion">News 1</button>
                    <div class="panel">
                        <h1 style = {{color: "black"}}>{this.state.date}</h1>
                        <p style={{ color: "black" }}>Heres a bunch of news straight from the news dude. Fresh news straight from the news mills</p>
                    </div>

                    <button class="accordion">News 2</button>
                    <div class="panel">
                        <p style={{ color: "black" }}>Look at that, even more news. News is spilling off the conveyor like that episode of Lucy, this is crazy.</p>
                    </div>

                    <button class="accordion">News 3</button>
                    <div class="panel">
                        <p style={{ color: "black" }}>Ok, I think its slowing down, wait I spoke too soon here comes the second wave. Pull the orange tab on your vest and it will inflate a flotation device for news emergencies.</p>
                    </div>
                </div>



            </div>
        );
    }



}