import React, { Component } from "react";
import {Button, InputGroup, FormControl, Card, Container, Row, Col} from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import "../assets/css/stdpage.css";
import Pokedex from "../assets/data/pokedex.json";


export default class JellyboneRandomizerPage extends Component {

    constructor(props){
        super(props);

        this.pokedex = []
        this.state = {
            currentDisplayNumber: "",
            currentPokemon: {"name": {"english": ""}, "type": ""},
            rangeFrom: 1,
            rangeTo: 150,
            incExNumber: 0,
            usedNumbers: [],
            availableNumbers: [],
            newRandButtonDisabled: false,
            errMsg: "",
            imgPeek: false
        }

        this.setNewRange = this.setNewRange.bind(this);
        this.onRangeFromChange = this.onRangeFromChange.bind(this);
        this.onRangeToChange = this.onRangeToChange.bind(this);
        this.getRandomNumber = this.getRandomNumber.bind(this);
        this.getAvailableNumbers = this.getAvailableNumbers.bind(this);
        this.onIncExChange = this.onIncExChange.bind(this);
        this.includeNumber = this.includeNumber.bind(this);
        this.excludeNumber = this.excludeNumber.bind(this);
        this.setGetRandomNumberStatus = this.setGetRandomNumberStatus.bind(this);
        this.togglePeek = this.togglePeek.bind(this);

        
    }

    componentDidMount(){
        var localRangeFrom = localStorage.getItem('jellybone-RangeFrom') || this.state.rangeFrom;
        var localRangeTo = localStorage.getItem('jellybone-RangeTo') || this.state.rangeTo;
        var storedUsedNumbers = localStorage.getItem('jellybone-UsedNumbers');
        console.log(storedUsedNumbers);
        var localUsedNumbers = (storedUsedNumbers) ? JSON.parse(storedUsedNumbers) : [];
        localStorage.setItem('jellybone-RangeFrom', localRangeFrom);
        localStorage.setItem('jellybone-RangeTo', localRangeTo);
        localStorage.setItem('jellybone-UsedNumbers', JSON.stringify(localUsedNumbers));
        var availNumbers = this.getAvailableNumbers(localUsedNumbers, localRangeFrom, localRangeTo);
        console.log("Avail Numbers during mount: ");
        console.log(availNumbers);
        this.setState({
            rangeFrom: localRangeFrom,
            rangeTo: localRangeTo,
            usedNumbers: localUsedNumbers,
            availableNumbers: availNumbers
        });
        this.setGetRandomNumberStatus(availNumbers);
    }

    togglePeek(){
        var newImgPeek = (!this.state.imgPeek);
        this.setState({
            imgPeek: newImgPeek
        });
    }

    onIncExChange(e){
        this.setState({
            incExNumber: e.target.value
        });
    }

    excludeNumber(){
        var nbr = parseInt(this.state.incExNumber);
        console.log("nbr to include: " + nbr);
        var usedNumbers = this.state.usedNumbers;
        const idx = usedNumbers.indexOf(nbr);
        if (idx===-1){
            usedNumbers.push(nbr);
            localStorage.setItem('jellybone-UsedNumbers', JSON.stringify(usedNumbers));
            var availNumbers = this.getAvailableNumbers(usedNumbers, this.state.rangeFrom, this.state.rangeTo);
            this.setState({
                usedNumbers: usedNumbers,
                availableNumbers: availNumbers,
                incExNumber: ""
            });
            this.setGetRandomNumberStatus(availNumbers);
            alert(nbr + " is now unavailable to be used.");
        }
    }

    includeNumber(){
        var nbr = parseInt(this.state.incExNumber);
        var usedNumbers = this.state.usedNumbers;
        const idx = usedNumbers.indexOf(nbr);
        if (idx>-1){
            console.log(typeof(usedNumbers));
            usedNumbers.splice(idx, 1);
            localStorage.setItem('jellybone-UsedNumbers', JSON.stringify(usedNumbers));
            var availNumbers = this.getAvailableNumbers(usedNumbers, this.state.rangeFrom, this.state.rangeTo); 
            this.setState({
                usedNumbers: usedNumbers,
                availableNumbers: availNumbers,
                incExNumber: ""
            });
            this.setGetRandomNumberStatus(availNumbers);
            alert(nbr + " is now available to be used.");
        }
    }

    setGetRandomNumberStatus(availNumbers){
        if (availNumbers.length<1){
            this.setState({
                errMsg: "You have no more available numbers for this range!",
                newRandButtonDisabled: true
            })
        }
        else{
            this.setState({
                errMsg: "",
                newRandButtonDisabled: false
            })
        }
    }


    getAvailableNumbers(usedNumbers, fromRange, toRange){
        console.log("used Numbers");
        console.log(usedNumbers);
        console.log("fromRange: " + fromRange);
        console.log("toRange: " + toRange);
        var fromRangeInt = parseInt(fromRange);
        var toRangeInt = parseInt(toRange);
        var result = [];
        for(var i=fromRangeInt; i<=toRangeInt; i++){
            if ((!usedNumbers) ||(!usedNumbers.includes(i))){
                result.push(i);
            }
        }
        console.log("getAvailableNumbers");
        console.log(result);
        return result;
    }


    onRangeFromChange(e){
        console.log(e.target.value);
        this.setState({
            rangeFrom: e.target.value
        })
    }

    onRangeToChange(e){
        console.log(e.target.value);
        this.setState({
            rangeTo: e.target.value
        })
    }

    setNewRange(){
        localStorage.setItem('jellybone-RangeFrom', this.state.rangeFrom);
        localStorage.setItem('jellybone-RangeTo', this.state.rangeTo);
        localStorage.setItem('jellybone-UsedNumbers', JSON.stringify([9999]));
        var availNumbers = this.getAvailableNumbers([], this.state.rangeFrom, this.state.rangeTo);
        console.log("available numbers");
        console.log(availNumbers);
        this.setState({
            usedNumbers: [],
            availableNumbers: availNumbers,
            errMsg: "",
            newRandButtonDisabled: false
        });
        this.setGetRandomNumberStatus(availNumbers);

    }

    getRandomNumber(){
        var min = Math.ceil(0);
        var max = Math.floor(this.state.availableNumbers.length-1);

        var randInt = Math.floor(Math.random() * (max - min) + min);
        console.log(this.state.availNumbers);
        console.log("randInt: " + randInt);
        var newDisplayNumber = this.state.availableNumbers[randInt];
        console.log("newDisplayNumber: " + newDisplayNumber);
        var usedNumbers = this.state.usedNumbers;
        usedNumbers.push(parseInt(newDisplayNumber));
        var availNumbers = this.getAvailableNumbers(usedNumbers, this.state.rangeFrom, this.state.rangeTo);
        localStorage.setItem('jellybone-UsedNumbers', JSON.stringify(usedNumbers));

        this.setState({
            usedNumbers: usedNumbers,
            availableNumbers: availNumbers,
            currentDisplayNumber: newDisplayNumber
        })
        var pokemon = Pokedex[newDisplayNumber-1];
        console.log(pokemon);
        this.setState({
            currentPokemon: pokemon
        })

        this.setGetRandomNumberStatus(availNumbers);
     
    }


  render() {

    const imgSrc = (this.state.imgPeek && this.state.currentPokemon.name.english!=="") ?
        "https://img.pokemondb.net/artwork/" + this.state.currentPokemon.name.english.toLocaleLowerCase().replace(' ', '') + ".jpg" :
         "https://scholasticadministrator.typepad.com/.a/6a00e54f8c25c988340120a75cd17f970b-800wi";
   

    return (
        <div className="page-container" style={{marginTop: "45px", marginLeft: "40px", marginRight: "40px"}}>
            <Row>
                    <hr style={{borderColor: "grey"}}/>
            </Row>
            <Row>
            <Col>
            <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                id="rangeFrom"
                value={this.state.rangeFrom}
                aria-label="From Number"
                aria-describedby="basic-addon1"
                onChange = {this.onRangeFromChange}
            />
            </InputGroup>
            </Col>
            <Col>
            <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                id="rangeTo"
                value={this.state.rangeTo}
                aria-label="To Number"
                aria-describedby="basic-addon1"
                onChange = {this.onRangeToChange}
            />
            </InputGroup>
            </Col>
            <Col>
            <Button
                variant="primary"
                onClick={this.setNewRange}
            >Set New Range
            </Button>
            </Col>

            </Row>

            <Row>
                <Col>
                <Button 
                    disabled={this.state.newRandButtonDisabled}
                    variant="primary"
                    onClick={this.getRandomNumber}
                
                >
                Get Random Number</Button>{' '}
                </Col>
                <Col>
                    {this.state.errMsg}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body style={{color: "black"}}>
                            <Card.Title>{this.state.currentDisplayNumber + " " + this.state.currentPokemon.name.english || ""}</Card.Title>
                        </Card.Body>
                        <Card.Text style={{paddingLeft: "30px"}}>
                            <span ><img src={imgSrc} alt="img"  width="50%" onClick={this.togglePeek}/></span>
                        </Card.Text>

                    </Card>
                </Col>
                <Col>
                    {(this.state.usedNumbers) ? 
                    this.state.usedNumbers.map((nbr) => <span>{nbr}, </span>)
                    : ""}
                </Col>
            </Row>
            <Row>
            <Col>
                <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Change Range</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                id="exclude"
                placeholder="enter number to include or exclude"
                aria-label="To Number"
                aria-describedby="basic-addon1"
                onChange = {this.onIncExChange}
            />
            </InputGroup>
            </Col>
            <Col>
            <Button id="includeButton" onClick={this.includeNumber}>Include</Button>
            <Button id="excludeButton" onClick={this.excludeNumber}>Exclude</Button>
            </Col>

            </Row>
        </div>
    );
  }


  
}